/* merriweather-300normal - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Merriweather Light '),
    local('Merriweather-Light'),
    url('./files/merriweather-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-300italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Merriweather Light italic'),
    local('Merriweather-Lightitalic'),
    url('./files/merriweather-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-400normal - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Merriweather Regular '),
    local('Merriweather-Regular'),
    url('./files/merriweather-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-400italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Merriweather Regular italic'),
    local('Merriweather-Regularitalic'),
    url('./files/merriweather-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-700normal - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Merriweather Bold '),
    local('Merriweather-Bold'),
    url('./files/merriweather-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-700italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Merriweather Bold italic'),
    local('Merriweather-Bolditalic'),
    url('./files/merriweather-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-900normal - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Merriweather Black '),
    local('Merriweather-Black'),
    url('./files/merriweather-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* merriweather-900italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Merriweather Black italic'),
    local('Merriweather-Blackitalic'),
    url('./files/merriweather-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/merriweather-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

